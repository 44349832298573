export const custom = {
  /**
  * Idiomas disponibles ['es','en','pt','cat']
  */
  availableLangs: ['es'],

  /**
   * Idioma por defecto: ['es']
   */
  defaultLang: 'es',

  /**
   * Versión de la API: v2|v3
   */
  apiVersion: 'v3',

  /**
   * API key de conexión a la api definida en cada entorno
   * (debe ser el mismo en todos los servicios)
   */
  apiKey: 'cf5ca5c0-a095-45d4-b8b8-56983a695b5b',

  /**
   * URL de la app
   *
   * subdominio.dominio
   */
  appUrlDomain: 'appsantaluciateayuda.alares',

  /**
  * Nombre de la app
  */
  appName: 'Santalucia Te Ayuda',

  /**
  * Código de analytics 'G-XXXXXXXXXXX'
  * (Activado en pro, desactivado en pre y local)
  */
  analyticsId: 'G-D7G0NZ5Q74',

  /** true o false. Define si es necesario hacer login para acceder a los contenidos */
  protected: true,

  /** Registro habilitado o deshabilitado */
  registration: false,

  /** true o false. Define si los formularios son para conciliación o no */
  conciliation: true
};
